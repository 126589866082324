import React, { Component } from "react"
import { graphql,Link } from "gatsby"
import Layout from "../components/layout"
import DatePicker from "react-datepicker";
import "../../node_modules/react-datepicker/dist/react-datepicker.css";
import $ from "jquery"
import AlertsSlider from "../components/alertsslider"
import GreenStar from "../images/star-green.svg"
import SEO from "../components/seo"
import NewsList from "../components/newslist"

class PageTemplate extends Component {
  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.Changedate = this.Changedate.bind(this)
    this.goToPage = this.goToPage.bind(this)
    this.prevPage = this.prevPage.bind(this)
    this.nextPage = this.nextPage.bind(this)

    this.state = {
      departmentsId : [],
      news: [],
      AllSingleNews: ``,
      date: null,
      endDate: null,
      firstTime: 0,
      pageNum: 1,
      pagesNum: 1,
      currentNews: []
    }
  }


  Changedate = (e) => {
    this.setState({
      date: e,
      departmentsId : this.state.departmentsId,
    });
  };

  ChangeEndDate = (e) => {
    this.setState({
      endDate: e,
      departmentsId : this.state.departmentsId,
    });
  };

  handleSubmit = event => {
     event.preventDefault()
     var moment = require('moment'); // require
     var allNews = this.state.AllSingleNews
     var allDepartmentsIds = this.state.departmentsId
     var tempGroup
     var filteredNews = []
     if(this.state.date !== null && this.state.endDate !== null && (allDepartmentsIds.includes('all') || allDepartmentsIds.length === 0)) {
         var startDateFormat = moment(this.state.date).format("YYYY-MM-DD");
         var endDateFormat = moment(this.state.endDate).format("YYYY-MM-DD");
         filteredNews = allNews.filter(item => {
          startDateFormat <= moment(item.node.created).format("YYYY-MM-DD") && moment(item.node.created).format("YYYY-MM-DD") <= endDateFormat ? (
              tempGroup = true
          ) : (
             tempGroup = false
          )
          return tempGroup
        })
     } else if(this.state.date !== null && this.state.endDate !== null) {
         var startDateFormat = moment(this.state.date).format("YYYY-MM-DD");
         var endDateFormat = moment(this.state.endDate).format("YYYY-MM-DD");
         filteredNews = allNews.filter(item => {
          startDateFormat <= moment(item.node.created).format("YYYY-MM-DD") && moment(item.node.created).format("YYYY-MM-DD") <= endDateFormat ? (
            item.node.relationships.group_content__departments_group_node_news.map((gr,key) => (
              key === 0 ? (tempGroup = false) : (null),
              allDepartmentsIds.includes(gr.relationships.gid.drupal_internal__id.toString()) ? (
                tempGroup = true
              ) : (
                null
              )
            ))
          ) : (
             tempGroup = false
          )
          return tempGroup
        })
     } else if(this.state.date !== null) {
      var startDateFormat = moment(this.state.date).format("YYYY-MM-DD");
      filteredNews = allNews.filter(item => {
        allDepartmentsIds.includes('all') ? (
          startDateFormat <= moment(item.node.created).format("YYYY-MM-DD") ? (
            tempGroup = true
          ) : (
            tempGroup = false
          )
        ) : (
          startDateFormat <= moment(item.node.created).format("YYYY-MM-DD") ? (
            item.node.relationships.group_content__departments_group_node_news !== null ? (
              item.node.relationships.group_content__departments_group_node_news.map((gr,key) => (
                allDepartmentsIds.includes(gr.relationships.gid.drupal_internal__id.toString()) ? (
                  tempGroup = true
                ) : (
                  tempGroup = false
                )
              ))
            ) : (
              tempGroup = false
            )
          ) : (
            tempGroup = false
          )
        )
        return tempGroup
      })
     } else if(this.state.endDate !== null) {
       var endDateFormat = moment(this.state.endDate).format("YYYY-MM-DD");
       filteredNews = allNews.filter(item => {
         allDepartmentsIds.includes('all') ? (
           moment(item.node.created).format("YYYY-MM-DD") <= endDateFormat ? (
             tempGroup = true
           ) : (
             tempGroup = false
           )
         ) : (
           moment(item.node.created).format("YYYY-MM-DD") <= endDateFormat ? (
             item.node.relationships.group_content__departments_group_node_news !== null ? (
               item.node.relationships.group_content__departments_group_node_news.map((gr,key) => (
                 allDepartmentsIds.includes(gr.relationships.gid.drupal_internal__id.toString()) ? (
                   tempGroup = true
                 ) : (
                   tempGroup = false
                 )
               ))
             ) : (
               tempGroup = false
             )
           ) : (
             tempGroup = false
           )
         )
         return tempGroup
       })
     } else {
        filteredNews = allNews.filter(item => {
          allDepartmentsIds.includes('all') ? (
            tempGroup = true
          ) : (
            item.node.relationships.group_content__departments_group_node_news !== null ? (
              item.node.relationships.group_content__departments_group_node_news.map((gr,key) => (
                allDepartmentsIds.includes(gr.relationships.gid.drupal_internal__id.toString()) ? (
                  tempGroup = true
                ) : (
                  tempGroup = false
                )
              ))
            ) : (
              tempGroup = false
            )
          )

          return tempGroup

        })
     }


      this.setState({
        news: filteredNews,
        AllSingleNews: this.state.AllSingleNews,
        departmentsId : this.state.departmentsId,
        date: this.state.date,
        endDate: this.state.endDate,
        firstTime: 1,
        pageNum: 1,
        pagesNum: Math.ceil(filteredNews.length / 10),
        currentNews: filteredNews.slice(0,10)
      });
   }

   handleChange = event => {
    if(event.target.checked) {
      this.state = {
        news: this.state.news,
        AllSingleNews: this.state.AllSingleNews,
        departmentsId: this.state.departmentsId.concat(event.target.value),
        date: this.state.date,
        endDate: this.state.endDate,
        firstTime: 1,
        pageNum: this.state.pageNum,
        pagesNum: this.state.pagesNum,
        currentNews: this.state.currentNews
      }
    } else {
      var array = this.state.departmentsId
      var index = array.indexOf(event.target.value)
      if (index !== -1) {
          array.splice(index, 1);
          this.state = {
            news: this.state.news,
            AllSingleNews: this.state.AllSingleNews,
            departmentsId : array,
            date: this.state.date,
            endDate: this.state.endDate,
            firstTime: 1,
            pageNum: this.state.pageNum,
            pagesNum: this.state.pagesNum,
            currentNews: this.state.currentNews
          }
      }
    }
  }

  prevPage = e => {
    var pageId = parseFloat(this.state.pageNum) - 1
    this.setState({
      news: this.state.news,
      AllSingleNews: this.state.AllSingleNews,
      departmentsId : this.state.departmentsId,
      date: this.state.date,
      endDate: this.state.endDate,
      firstTime: 1,
      pageNum: pageId,
      pagesNum: this.state.pagesNum,
      currentNews: this.state.news.slice(parseFloat((pageId - 1) * 10), (pageId * 10))
    })
  }

  nextPage = e => {
    var pageId = parseFloat(this.state.pageNum) + 1
    this.setState({
      news: this.state.news,
      AllSingleNews: this.state.AllSingleNews,
      departmentsId : this.state.departmentsId,
      date: this.state.date,
      endDate: this.state.endDate,
      firstTime: 1,
      pageNum: pageId,
      pagesNum: this.state.pagesNum,
      currentNews: this.state.news.slice(parseFloat((pageId - 1) * 10), (pageId * 10))
    })
  }


  goToPage = (e) => {
    var pageId = parseFloat(e.target.dataset.page)
    if(pageId === 1) {
      this.setState({
        news: this.state.news,
        AllSingleNews: this.state.AllSingleNews,
        departmentsId : this.state.departmentsId,
        date: this.state.date,
        endDate: this.state.endDate,
        firstTime: 1,
        pageNum: pageId,
        pagesNum: this.state.pagesNum,
        currentNews: this.state.news.slice(0,10)
      })
    } else {
      //this.setState({ currentDocs: this.state.documents.slice(parseFloat((pageId - 1) * 10), (pageId * 10)) })
      this.setState({
        currentNews: this.state.news.slice(parseFloat((pageId - 1) * 10), (pageId * 10)),
        news: this.state.news,
        AllSingleNews: this.state.AllSingleNews,
        departmentsId : this.state.departmentsId,
        date: this.state.date,
        endDate: this.state.endDate,
        firstTime: 1,
        pageNum: pageId,
        pagesNum: this.state.pagesNum
      })
    }

  }

  componentDidMount() {
    var es = this;
    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return false;
    };

    if(getUrlParameter('guid')) {
      setTimeout(function(){
        $('.select-all').hide()
        $('label[class^="label"]').hide();
        $(".label"+getUrlParameter('guid')).show().click()
        $(".confirm-button").click()
      },100)
      setTimeout(function(){
        $(".confirm-button").click()
      },200)
    }
    if($('.events-filters').length > 0) {
      setTimeout(function(){
        $('.select-all').click();
        es.setState( () => {
          return {
            AllSingleNews: es.state.AllSingleNews,
            news: es.state.news,
            departmentsId: ['all'],
            date: null,
            endDate: null,
            firstTime: 1,
            pageNum: 1,
            pagesNum: Math.ceil(es.state.AllSingleNews.length / 10),
            currentNews: es.state.news.slice(0,10)
          }
        });
      }, 100);
    }
    $('.events-filters .clear-all').click(function(e) {
      e.preventDefault();
      $('.events-filters .uk-grid .label').each(function() {
        if($(this).find('input')[0].checked == true) {
          $(this).click();
        }
      });
      if($('.select-all input')[0].checked == false) {
        $('.select-all').click();
      }
      $('.date-range input').each(function() {
        $(this).val(null);
      });

      es.setState( () => {
        return {
          AllSingleNews: es.state.AllSingleNews,
          news: es.state.news,
          departmentsId: ['all'],
          date: null,
          endDate: null,
          firstTime: 1,
          pageNum: 1,
          pagesNum: Math.ceil(es.state.AllSingleNews.length / 10),
          currentNews: es.state.news.slice(0,10)
        }
      });
    });

    $('.events-filters .uk-grid .label').click(function(){
      if($('.select-all input')[0].checked ) {
        $('.select-all').click();
      }
    });

    $('.filter-dropdown').click(function() {
      $('.overlay-filter').addClass('active-overlay');
    });

    $('.events-filters .confirm-button').click(function() {
      $('.overlay-filter').removeClass('active-overlay');
    });
    $('.overlay-filter').click(function() {
      $('.overlay-filter').removeClass('active-overlay');
      $('#toggle-usage').prop('hidden', true);
      $('.filter-dropdown')[0].ariaExpanded = 'false';
    });

  }


  render() {
    // const [dateValue, onChangeDate] = useState(new Date());
    var moment = require('moment');
    const currentPage = this.props.data.nodePage
    const alerts = this.props.data.allNodeAlert
    var allNewsTempGlobal = this.props.data.allNodeNews.edges
    var allNewsTemp = this.props.data.allNodeNews.edges.filter(item => (item.node.relationships.group_content__departments_group_node_news !== null))
    const allLandingPages = this.props.data.allNodeLandingPage.edges
    var filterAllDepartments = allLandingPages.filter(item => item.node.field_include_in_list || item.node.relationships.field_parent_agency === null)
    const meta = currentPage.relationships.field_meta_data
    filterAllDepartments = filterAllDepartments.filter(item => item.node.relationships.group_content__group_content_type_20b7e70e3cc2b !== null)

    var landingPageRel = []
    var landningGroup
    allLandingPages && allLandingPages.map((page,key) => (

      page.node.relationships.group_content__group_content_type_20b7e70e3cc2b ? (
        landningGroup = page.node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id,
        landingPageRel[landningGroup] = [],
        landingPageRel[landningGroup]['title'] = page.node.relationships.field_hero.field_title_html.value,
        landingPageRel[landningGroup]['path'] = page.node.path.alias

      ) : (
        null
      )
    ))


    var featuredNews = []
    allNewsTempGlobal.map((featured, index) => (
      featured.node.field_featured_post === true ? (
        featuredNews.push(featured)
      ) : (
        null
      )
    ))
    featuredNews = featuredNews.slice(0, 3)
    featuredNews.map(featured => {
      featured.featured_time = moment(featured.created).format("dddd, MMMM D")
    })

    if(this.state.firstTime === 0) {
      this.state = {
        AllSingleNews: allNewsTemp,
        news: allNewsTempGlobal,
        departmentsId: this.state.departmentsId,
        date: this.state.date,
        endDate: this.state.endDate,
        firstTime: this.state.firstTime,
        pageNum: this.state.pageNum,
        pagesNum: Math.ceil(allNewsTempGlobal.length / 10),
        currentNews: allNewsTempGlobal.slice(0,10)
      }
    } else if (this.state.departmentsId.includes('all') && this.state.date === null && this.state.endDate === null) {
      this.state = {
        AllSingleNews: allNewsTemp,
        news: allNewsTempGlobal,
        departmentsId: this.state.departmentsId,
        date: this.state.date,
        endDate: this.state.endDate,
        firstTime: this.state.firstTime,
        pageNum: this.state.pageNum,
        pagesNum: Math.ceil(allNewsTempGlobal.length / 10),
        currentNews: this.state.currentNews
      }
    } else {
      this.state = {
        AllSingleNews: allNewsTemp,
        news: this.state.news,
        departmentsId: this.state.departmentsId,
        date: this.state.date,
        endDate: this.state.endDate,
        firstTime: this.state.firstTime,
        pageNum: this.state.pageNum,
        pagesNum: this.state.pagesNum,
        currentNews: this.state.currentNews
      }
    }

    return (
        <Layout >
        <SEO
            title={currentPage.title}
            contentBody={currentPage.body}
            metaData={meta}
            locationOrigin={this.props.location.origin + currentPage.path.alias}
            domen={this.props.location.origin}
        />
        <div className="overlay-filter"></div>
        <div className="site-content news uk-padding-remove-top">

          <AlertsSlider allAlerts={alerts.edges} gruopId={null} globalPage={true} />

          <div className="container uk-margin-medium-top">

            <div className="title-filters uk-flex-middle">
              <h1 className="uk-margin-remove-bottom">Press Releases</h1>
              <div className="button-wrp">
                <a className="uk-button filter-dropdown uk-margin-remove-bottom" type="button" data-uk-toggle="target: #toggle-usage" data-aria-expanded="true">Filters</a>
              </div>
            </div>

            <div className="toggle" hidden id="toggle-usage">
            <div className="close-event-filter"></div>
              <form className="events-filters" onSubmit={this.handleSubmit}>

                <h2 className="h3">Departments</h2>

                <div>
                  <label className="select-all">
                      <input type="checkbox" name="events-all-departments" id="events-all-departments" value='all' onChange={this.handleChange} />
                      All Departments
                  </label>
                </div>

                <div className="uk-grid uk-child-width-1-4@m" data-uk-grid>
                  <div>

                  {filterAllDepartments && filterAllDepartments.map((department,key) => (
                    key < 10 ? (
                      <label className={"label label" + department.node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id} key={"department" + key}>
                        <input type="checkbox" name="settings[]" value={department.node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id} onChange={this.handleChange}/>
                        <span>{department.node.relationships.field_hero.field_title_html.value}</span>
                      </label>
                    ) : (
                      null
                    )
                  ))}
                  </div>
                  <div>
                    {filterAllDepartments && filterAllDepartments.map((department,key) => (
                      key >= 10 && key < 20 ? (
                        <label className={"label label" + department.node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id} key={"department" + key}>
                          <input type="checkbox" name="settings[]" value={department.node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id} onChange={this.handleChange}/>
                          <span>{department.node.relationships.field_hero.field_title_html.value}</span>
                        </label>
                      ) : (
                        null
                      )
                    ))}
                  </div>
                  <div>
                    {filterAllDepartments && filterAllDepartments.map((department,key) => (
                      key >= 20 && key < 30 ? (
                        <label className={"label label" + department.node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id} key={"department" + key}>
                          <input type="checkbox" name="settings[]" value={department.node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id} onChange={this.handleChange}/>
                          <span>{department.node.relationships.field_hero.field_title_html.value}</span>
                        </label>
                      ) : (
                        null
                      )
                    ))}
                  </div>
                  <div>
                    {filterAllDepartments && filterAllDepartments.map((department,key) => (
                       key >= 30 ? (
                        <label className={"label label" + department.node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id} key={"department" + key}>
                          <input type="checkbox" name="settings[]" value={department.node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id} onChange={this.handleChange}/>
                          <span>{department.node.relationships.field_hero.field_title_html.value}</span>
                        </label>
                      ) : (
                        null
                      )
                    ))}

                  </div>
                </div>

                <div className="uk-grid date-range-confirm uk-flex-middle" data-uk-grid>
                  <div className="date-range uk-width-1-1 uk-width-expand@m">
                    <h2 className="h3">Date Range</h2>

                    <DatePicker className="form-control" id="date"
                        selected={this.state.date} placeholderText="Start Date" showPopperArrow={false}
                        onChange={this.Changedate} autoComplete="off"
                      />

                      <DatePicker className="form-control" id="today"
                        selected={this.state.endDate} placeholderText="End Date" showPopperArrow={false}
                        onChange={this.ChangeEndDate} autoComplete="off"
                      />

                  </div>
                  <div className="clear-confirm uk-width-1-1 uk-width-auto@m">
                    <input className="clear-all" type="reset" value="Clear All" />
                    <input className="confirm-button" data-uk-toggle="target: #toggle-usage" type="submit" value="Confirm" />
                  </div>
                </div>


              </form>
            </div>
          </div>

          <section className="featured-news uk-padding-remove-top">
            <div className="container">
              <div className="featured-news-title">
                <img src={GreenStar} alt="green star"/>
                <h2 className="h5">Featured News</h2>
              </div>
              <div className="uk-grid-small uk-flex uk-child-width-1-3@m uk-grid-match" data-uk-grid>
                {featuredNews.map((featured, index) => (
                  <div key={index}>
                    <div className=" uk-card uk-flex uk-flex-column news-card">
                      {featured.node.relationships.field_media_image !== null && featured.node.relationships.field_media_image.relationships.field_media_image.uri.url !== null ? (
                        <Link to={featured.node.path.alias} aria-label={featured.node.title + " open news card"}><div className="uk-card-media-top" style={{ backgroundImage: `url(${featured.node.relationships.field_media_image.relationships.field_media_image.uri.url})` }}/></Link>
                      ) : (
                        null
                      )}
                      <div className="uk-card-header">{featured.node.featured_time}</div>
                      <div className="uk-card-body">
                        <Link to={featured.node.path.alias}><h5>{featured.node.title}</h5></Link>
                      </div>
                      <div className="uk-card-footer">
                        {featured.node.relationships.group_content__departments_group_node_news && featured.node.relationships.group_content__departments_group_node_news.map((group,key) => (
                            <Link key={"grouplink"+key} to={landingPageRel[group.relationships.gid.drupal_internal__id]['path']}>{landingPageRel[group.relationships.gid.drupal_internal__id]['title']}<br /></Link>
                          ))}

                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <div className="container">
            <div className="all-news">
              {this.state.currentNews.length > 0 ? (
                <NewsList news={this.state.currentNews} relarray={landingPageRel} currentDepartment={null} />
              ) : (
                  <p>
                  We found no results. Please clear the filter and start over.
                  </p>
              )}

              {this.state.news && this.state.news.length > 10 ? (
                <ul className="uk-pagination uk-flex-center">
                  {this.state.pageNum !== 1 ? (
                    <li className="uk-margin-auto-right" key="pagfirst">
                      <a className="prev" onClick={this.prevPage} href="#">Previous</a>
                    </li>
                    ) : (
                      <li className="uk-margin-auto-right" ket="pagfirst">

                      </li>
                  )}

                  {this.state.pageNum - 3 > 0 ? (
                    <>
                      <li key="pagfirst">
                        <a onClick={this.goToPage} data-page="1" href="#">1</a>
                      </li>
                      <li ket="pagdotfirst">
                      <span>...</span>
                      </li>
                    </>

                  ) : (
                    null
                  )}

                  {[...Array(this.state.pagesNum)].map((number,key) => (
                      key > this.state.pageNum - 4 && key < this.state.pageNum + 2 ? (
                        this.state.pageNum === key + 1 ? (
                          <li className="uk-active" key={"pag" + key}>
                            <a onClick={this.goToPage} data-page={key + 1} href="#">{key + 1}</a>
                          </li>
                        ) : (
                          <li key={"pag" + key}>
                            <a onClick={this.goToPage} data-page={key + 1} href="#">{key + 1}</a>
                          </li>
                        )
                      ) : (
                        null
                      )

                  ))}

                  {this.state.pageNum + 2 < this.state.pagesNum ? (
                    <>
                      <li key={"pagdot"}>
                      <span>...</span>
                      </li>
                      <li key={"paglast"}>
                        <a onClick={this.goToPage} data-page={this.state.pagesNum} href="#">{this.state.pagesNum}</a>
                      </li>
                    </>

                  ) : (
                    null
                  )}

                  {this.state.pageNum !== this.state.pagesNum ? (
                    <li className="uk-margin-auto-left" key={"pagprev"}>
                      <a className="next" href="#" onClick={this.nextPage}>Next</a>
                    </li>
                  ) : (
                    <li className="uk-margin-auto-left" key={"pagprev"}>

                    </li>
                  )}

                </ul>

              ) : (
                null
              )}

            </div>

          </div> {/*container*/}
        </div>

        </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    nodePage(id: { eq: $id }) {
      title
      path {
        alias
      }
      body {
        value
      }
      relationships {
        field_meta_data {
          ...paragraph__meta_dataFragment
        }
      }
    }
    allGroupDepartments(sort: {fields: label}) {
      edges {
        node {
          label
          drupal_internal__id
        }
      }
    }



    allNodeAlert(filter: {field_global_post: {eq: true}},sort: {fields: field_weight, order: ASC}) {
      edges {
        node {
          title
          field_alert_text
          field_weight
          field_link {
            title
            uri
          }
          field_alert_type
          field_global_post
          relationships {
            group_content__departments_group_node_alert {
              relationships {
                gid {
                  drupal_internal__id
                }
              }
            }
          }
        }
      }
    }

    allNodeLandingPage(sort: {fields: relationships___field_hero___field_title_html___value}) {
      edges {
        ...node__landing_pageEdgeFragment
      }
    }

    allNodeNews(sort: {fields: created, order: DESC}) {
      edges {
        node {
          created
          title
          body {
            value
          }
          path {
            alias
          }
          field_featured_post
          relationships {
            field_media_image {
              relationships {
                field_media_image {
                  uri {
                    url
                  }
                }
              }
            }
            group_content__departments_group_node_news {
              relationships {
                gid {
                  drupal_internal__id
                  label
                }
              }
            }
          }
        }
      }
    }
  }
`
